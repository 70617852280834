import React from 'react'
import curateImg from '../assets/web-screenshots/Curate.png'
import illuminateImg from '../assets/web-screenshots/Illuminate.png'
import kitchenImg from '../assets/web-screenshots/Kitchen.png'
import squatcherImg from '../assets/web-screenshots/Squatcher.png'
import bingewatcherImg from '../assets/web-screenshots/Bingewatcher.png'

export default function Works() {


    const projects = [
        // {id: 0, name: "", description: "", frontend: null, backend: null, repo: null, date: "", screenshot: ""}
        { 
          id: 1, 
          name: "CURATE", 
          description: "CURATE is an application that allows users to search the MET Museum's open archive and make their own exhibitions with over 500,000 pieces of art from across human history. This is the final project for my software engineering bootcamp at Flatiron School. It was built with a Ruby on Rails backend, a React frontend, and the MET Open Acress API.", 
          frontend: "https://github.com/amodttech/Curate-frontend", 
          backend: "https://github.com/amodttech/Curate-backend", 
          repo: null, 
          date: "February 2021", 
          screenshot: curateImg
        },
        {
          id: 2, 
          name: "Illuminate", 
          description: "Illuminate is a repository for stained glass patterns, where users can share patterns and completed projects.  This is a project for my software engineering bootcamp at Flatiron School. It was built with a Ruby on Rails backend and a React frontend.", 
          frontend: "https://github.com/ARElton/illuminate-frontend", 
          backend: "https://github.com/ARElton/illuminate-backend", 
          repo: null, 
          date: "January 2021", 
          screenshot: illuminateImg
        },
        {
          id: 3, 
          name: "Kitchen", 
          description: "Kitchen is a single-page snack recipe saving app. This was my 3rd project for Flatiron School.  Built with a Ruby on Rails backend, and Javascript frontend.", 
          frontend: "https://github.com/saracemal/kitchen-frontend", 
          backend: "https://github.com/saracemal/kitchen-backend", 
          repo: null, 
          date: "December 2020", 
          screenshot: kitchenImg
        },
        {
          id: 4, 
          name: "Squatcher", 
          description: "User is on a hunt for Bigfoot & his cryptozoological friends. They will be able to save their sightings into the database.  First web-app project for Flatiron School.  Built entirely in Ruby on Rails", 
          frontend: null, 
          backend: null, 
          repo: "https://github.com/kbotero201/SQUATCHER", 
          date: "November 2020", 
          screenshot: squatcherImg
        },
        {
          id: 5, 
          name: "Bingewatcher", 
          description: "A personal movie review app.  This was my first programming project for Flatiron School.  CLI app built entirely in Ruby.", 
          frontend: null, 
          backend: null, 
          repo: "https://github.com/amodttech/Bingewatcher", 
          date: "November 2020", 
          screenshot: bingewatcherImg
        }]
  
      const projectList = projects.map(project => 
        <div id={project.id} className="app-container">
          <h4>{project.name}</h4>
          <p>{project.description}</p>
          {project.repo ? 
            <p>
              <a href={project.repo}>Github</a>
            </p> : 
            <p>
              <span className="repo-link"><a href={project.frontend}>Github Frontend</a></span>  
              <span className="repo-link"><a href={project.backend}>Github Backend</a></span>
            </p>}
          <p>{project.date}</p>
          <a href={project.screenshot} target="blank"><img src={project.screenshot} className="proj-img" alt={project.name} /></a>
        </div>
        )



    return (
        <div className="content-body">
            {projectList}
        </div>
    )
}
