import React from 'react'
import aaronPinnacle from '../assets/aaron-pinnacles.jpg'

export default function Home() {
    return (
        <div className="content-body">
            <div className="portrait">
                <img src={aaronPinnacle} alt="Me at the Pinnacles"/>
            </div>
        </div>
    )
}
