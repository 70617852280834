import React from 'react'

function About() {
    return (
        <div className="content-body">
            <p>Frontend: HTML, CSS, Javascript, React, Redux, Bootstrap</p>
            <p>Backend: Ruby, RoR, SQLite3, Postgres</p>
            <p>Physical Specialities: Wood, Metal, Fire, Fibers, Circuits, Photons, Ephemera.</p>
            <p>Veteran touring stagehand and rookie fullstack software engineer. Prior to the 2020 Pandemic I worked as a freelance technical director and pyrotechnician. Now I'm offering my technical and crisis management skills to the world of web development.</p>
            <p>I tinker but I always clean up my messes.</p>
        </div>
    )
}

export default About
