import React from 'react';
import { Switch, Route, Link} from "react-router-dom";

import Footer from "./components/Footer"
import About from "./components/About"
import Works from "./components/Works"
import Home from "./components/Home"

function App() {

  return (
      <div className="App">
        <Header />
        <NavBar />
        
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/works">
              <Works />
            </Route>
          </Switch>

        <Footer />
      </div>
  );
}

export default App;

function NavBar() {
  return (
    <ul className="navbar">
      <li><Link to="/about">About</Link></li>
      <li><Link to="/works">Works</Link></li>
    </ul>
    
  )
}

function Header() {
  return (
    <div>
      <Link to="/"><h1>Aaron Amodt</h1></Link>
    </div>
  )
}
