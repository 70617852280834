import React from 'react'

export default function Footer() {
    return (
        <div className="footer">
            <p>A FINE INTERNET PRODUCT BY <a className="name-span" href="mailto:info@amodttech.com?subject=I visited Amodttech.com">AARON AMODT</a> ⓒ 2021</p>
        </div>
    )
}


